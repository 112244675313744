/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { Menu, CartMini } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu, { useHideSubMenu } from "components/AppHeader/Large/SubMenu";
import CartCounter from "components/AppHeader/CartCounter";
import HamburgerIcon from "icons/hamburger.svg";
import AdminButton from "components/AccountView/AdminButton";
import useOnFullMenuHiddenRoute from "helpers/use-on-full-menu-hidden-route";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  isScrolled: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({
  className = "",
  mode,
  setMode,
  onHomeView,
  onCheckout,
  lastVisited,
  customer,
  logoWidth,
  isScrolled,
  onSuccess,
}: Props): React$Node => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const { routes: { homeView, accountView, suppliersView, checkoutView } } = storeInfo;
  const { content: { appheader, accountview } } = storeInfo;
  const { formatPoints } = useFormat();
  const { location: { pathname } } = useHistory();
  const hideSubMenu = useHideSubMenu();
  const showAdminButton = Boolean(accountview.showAdministration) &&
    hideSubMenu &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;
  const compactMenu = appheader.compactMenu !== undefined &&
    appheader.compactMenu !== null &&
    appheader.compactMenu === true;

  const nav = navRoutes(ROUTES, storeInfo);
  const onFullMenuHiddenRoute = useOnFullMenuHiddenRoute();

  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={cn("awardit-appHeaderContainerLarge", styles.container)}>
      <div className={cn(
        styles.block,
        className,
        { [styles.fullMenu]: onHomeView },
        { [styles.onHomeView]: onHomeView },
        { [styles.noSubMenu]: onFullMenuHiddenRoute && !onHomeView })}
      >

        {homeView && homeView.url &&
          <Link className={cn(styles.item, styles.logo)} to={homeView.url ?? "/"}>
            <Logo
              width={logoWidth}
              isInverted={!isScrolled}
              onClick={() => setMode(MODE.NORMAL)}
            />
          </Link>
        }

        <nav className={cn("awardit-appHeaderContainerLargeNav", styles.nav)}>
          {!onCheckout && !compactMenu && nav.map(x => (x.url &&
            <NavLink
              key={x.url}
              to={x.url}
              activeClassName={styles.active}
              className={styles.item}
            >
              {x.title}
            </NavLink>
          ))}

          {!onCheckout && !compactMenu && suppliersView?.url &&
            suppliersView.toggle !== undefined && suppliersView.toggle && (
            <NavLink
              key={suppliersView.url}
              to={suppliersView.url}
              activeClassName={styles.active}
              className={styles.item}
            >
              {suppliersView.title}
            </NavLink>
          )}

          {compactMenu &&
            <Menu className={styles.item} icon={<HamburgerIcon />}>
              {!onCheckout && nav.map(x => (x.url &&
                <Link key={x.url} to={x.url} className={styles.menuItem}>
                  {x.title}
                </Link>
              ))}
              {!onCheckout && suppliersView?.url && suppliersView.toggle !== undefined &&
                suppliersView.toggle && (
                <Link to={suppliersView.url} className={styles.menuItem}>
                  {suppliersView.title}
                </Link>
              )}
            </Menu>
          }

          {(expiringPointsDate && expiringPoints &&
            accountView && accountView.url) && (
            <NavLink
              to={accountView.url}
              className={cn(styles.item, styles.expiresNotice)}
            >
              <div className={styles.exclamation}>!</div>
              <div className={styles.notice}>
                <span className={styles.date}>
                  {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
                </span>
                <span className={styles.points}>
                  {expiringPoints}
                </span>
              </div>
            </NavLink>
          )}

          {(accountView?.url && accountView.toggle !== undefined &&
            accountView.toggle) && (
            <NavLink
              to={accountView.url}
              activeClassName={styles.active}
              className={cn(styles.item, styles.item__customerBadge)}
            >
              <CustomerBadge customer={customer} />
            </NavLink>
          )}

          {!pathname.includes("/checkout/") && (
            <CartCounter
              className={
                cn("awardit-appHeaderCartCounter", styles.item, styles.item__cart, { [styles.active]: mode === MODE.CART })}
              isOpen={mode === MODE.CART}
              openMiniCart={() => setMode(MODE.CART)}
            />
          )}
          {!pathname.includes("/checkout/") && mode === MODE.CART &&
            checkoutView && checkoutView.url && (
            <CartMini
              quoteData={quoteData}
              variant="b2b"
              altUrl={`${checkoutView.url}/cart`}
              ctaUrl={`${checkoutView.url}/shipping`}
              className={cn(styles.desktop, styles.miniCart)}
              onClose={() => setMode(MODE.NORMAL)}
            />
          )}

          {showAdminButton &&
            <div className={cn(styles.adminBtnContainer, "awardit-adminButton")}>
              {accountview.administration &&
                <AdminButton text={accountview.administration} />
              }
            </div>
          }
        </nav>
      </div>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess} />

      {!noDimModes.includes(mode) &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;
