/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import LoginForm from "components/LoginForm";
import { useErrorParams } from "helpers/use-error-params";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage } from "crustate/react";
import { setMode, MODE } from "state/view-mode";
import Button from "components/Button";

import styles from "./styles.scss";

const LoginView = (): React$Node => {
  const {
    routes,
    content: { loginview, appfooter },
    configuration,
    configuration: { showCookieConsent, showPrivacyNotice },
  } = useContext(StoreInfoContext);
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const termsLink = (
    routes.termsView && routes.termsView.toggle !== undefined && routes.termsView.toggle &&
    routes.termsView.url !== undefined && routes.termsView.url &&
    routes.termsView.title !== undefined && routes.termsView.title) ? {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  return (
    <div className={cn(styles.block, "awardit-loginView")}>
      <Helmet title={loginview.pageTitle} />
      <div className={styles.top}>
        <LoginForm className={styles.login_form} />
      </div>
      <div className={cn(styles.content, "awardit-loginViewContent")}>
        <div className={cn(styles.bottom, "awardit-loginViewBottom")}>
          <div className={styles.inner}>
            <h1>{loginview.heading}</h1>
            {/* eslint-disable react/no-danger */}
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: loginview.description }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
          <div className={styles.footer}>
            <div className={styles.footerInner}>
              {loginview.customerServiceButtonUrl && appfooter.copyrightText && (
                <span>{appfooter.copyrightText}</span>
              )}

              <ul className={styles.footerLinks}>
                {consentActivated &&
                  <li>
                    <Button
                      className={styles.cookieButton}
                      onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                    >
                      {t("FOOTER.NEED_HELP.COOKIES")}
                    </Button>
                  </li>
                }
                {Boolean(showPrivacyNotice) && (
                  <li>
                    <Link className={styles.link} to="privacy-notice">
                      {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                    </Link>
                  </li>
                )}
                {Boolean(loginview.showShopTermsLink) && (
                  <li>
                    <Link className={styles.link} to={shopTermsUrl}>
                      {t("FOOTER.LEGAL.TERMS")}
                    </Link>
                  </li>
                )}
                {termsLink && (
                  <li>
                    <Link className={styles.link} to={termsLink.url}>
                      {termsLink.title}
                    </Link>
                  </li>
                )}
              </ul>

              {loginview.customerServiceButtonUrl && (
                <nav>
                  <a href={loginview.customerServiceButtonUrl}>
                    {loginview.customerServiceButtonText}
                  </a>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
