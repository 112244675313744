/* @flow */

import { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";

const useOnFullMenuHiddenRoute = (): boolean => {
  const { routes, configuration } = useContext(StoreInfoContext);
  const { pathname } = useLocation();

  // Only allow submenu for earnView subpages
  const earnView = ((routes.earnViewSubpage && routes.earnViewSubpage.toggle) ||
  (routes.earnViewSubpage2 && routes.earnViewSubpage2.toggle));

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  const FULL_MENU_HIDDEN_ROUTES = useMemo(() => {
    const routeUrls = [
      routes.accountView?.url,
      routes.currentInfoView1?.url,
      routes.currentInfoView2?.url,
      routes.currentInfoView3?.url,
      routes.currentInfoView4?.url,
      routes.currentInfoView5?.url,
      routes.currentInfoView6?.url,
      routes.formListView?.url,
      routes.newsListView?.url,
      routes.offerListView?.url,
      routes.quizHistory?.url,
      routes.quizList?.url,
      routes.suppliersView?.url,
      routes.termsView?.url,
      "/forms",
      "/privacy-notice",
      shopTermsUrl,
      earnView === false ? routes.earnView?.url : undefined,
    ];

    return routeUrls.filter(Boolean);
  }, [routes]);

  return FULL_MENU_HIDDEN_ROUTES.some(route => pathname.includes(route));
};

export default useOnFullMenuHiddenRoute;
