/* @flow */

import React, { useContext } from "react";

import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import LogoList from "components/LogoList";
import { Title } from "components/UiComponents";
import Wrapper from "components/Wrapper";
import { suppliers as suppliersData } from "data/suppliers";

import styles from "./styles.scss";

const SuppliersView = (): React$Node => {
  const { info, routes } = useContext(StoreInfoContext);
  const { partnerId } = info;
  return (
    <div className={styles.block}>
      <Helmet title={routes.suppliersView?.title} />
      {suppliersData && suppliersData[partnerId] && (
        <Wrapper>
          <div className={styles.contentWrapper}>
            {routes.suppliersView && routes.suppliersView.title && (
              <Title elem="h1">{routes.suppliersView.title}</Title>
            )}
            <LogoList logos={suppliersData[partnerId]} />
          </div>
        </Wrapper>
      )}
    </div>
  );
};

export default SuppliersView;
