/* @flow */

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { OffCanvasFilterMenu, ActiveFilters } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import { getCurrentPageInfo } from "state/current-page-info";
import { useSendMessage } from "crustate/react";
import Button from "components/Button";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import FilterIcon from "icons/filter.svg";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { getSubPageCards } from "helpers/utils";
import useAffiliateListFilter from "components/AffiliateListView/use-affiliate-list-filter";
import SearchInput from "components/AffiliateListView/SearchInput";
import DropdownSort from "components/DropdownSort";
import ErrorView from "components/ErrorView";
import { Section, Title } from "components/UiComponents";
import TopLevelCard from "components/AffiliateListView/TopLevelCard";

import styles from "./styles.scss";

type Props = {
  title: string,
  description: string,
};

const NUM_DUMMYCARDS = 32;

const AffiliateSubpage = ({ title, description }: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { routes, content: { earnview } } = useContext(StoreInfoContext);
  const openFiltermenu = useOpenFiltermenu();
  const { location: { pathname } } = useHistory();
  const earnViewSubpageUrl = routes.earnViewSubpage && routes.earnViewSubpage.url ? routes.earnViewSubpage.url : "";

  const {
    filterState,
    items,
    searchValue,
    setSearchValue,
    affiliateCategoriesLoaded,
    affiliateListLoaded,
    affiliateCategoryError,
    affiliateListError } = useAffiliateListFilter();

  const subPagecards = getSubPageCards(earnview, pathname, earnViewSubpageUrl);

  useEffect(() => {
    sendMessage(getCurrentPageInfo("EARNVIEW"));
  }, []);

  return (
    <div className={styles.block}>
      <Helmet
        title={earnview.pageTitle}
      />
      <Wrapper variant="pageWrapper" className={cn(styles.wrapper, "awardit-affiliateListView awardit-pageWrapper")}>
        {(title || description) &&
          <div className={cn(styles.intro, "awardit-affiliateListViewIntro")}>
            {title &&
            <>
              <Title>
                {title}
              </Title>
              {/* eslint-disable react/no-danger */}
              {description &&
                <div dangerouslySetInnerHTML={{ __html: description }} />
              }
              {/* eslint-enable react/no-danger */}
            </>
            }
          </div>
        }

        {subPagecards.length > 0 &&
          <div className={styles.cardsWrapper}>
            {subPagecards.map((card, i) => (
              <TopLevelCard
                key={i}
                heading={card.heading}
                image={card.image}
                linkUrl={card.link && card.link}
                description={card.description && card.description}
              />
            ))
            }
          </div>
        }

        <div className={styles.activeFiltersRow}>
          {filterState.active.filters.length > 0 &&
            <h2 className={styles.activeFiltersRowHeader}>
              {t("FILTER.ACTIVE_FILTERS.COUNT")}
              <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
            </h2>
          }
          <ActiveFilters
            hideEmptyFilters
            className={styles.activeFilters}
            filterState={filterState}
          />
        </div>

        <Section className={styles.section}>
          {affiliateCategoriesLoaded &&
          <div>
            <div className={styles.filterWrapper}>
              <div className={styles.filterSection}>
                <Button
                  variant="medium"
                  slotLeft={<FilterIcon className={styles.filterIcon} />}
                  className={styles.filterButton}
                  onClick={openFiltermenu.openFiltermenu}
                >
                  {t("FILTER.FILTERBAR.ALL_FILTERS")}
                </Button>
                <div className={styles.inputs}>
                  <SearchInput
                    value={searchValue}
                    placeholderText={t("EARN.SEARCH")}
                    clearButtonAriaLabel={t("EARN.CLEAR_SEARCH")}
                    onChange={setSearchValue} />
                  <DropdownSort
                    className={styles.affiliateSort}
                    value={filterState.sort.value}
                    items={filterState.sort.values}
                    onChange={filterState.sort.setValue} />
                </div>
              </div>
            </div>

            {affiliateListLoaded && items.length > 0 &&
            <AffiliateList items={items} />
            }

            {affiliateListLoaded && items.length === 0 &&
            <div className={styles.emptyList}>
              <p>{earnview.emptyListMessage}</p>
            </div>
            }
          </div>
          }

          {!affiliateListLoaded && !affiliateListError &&
            <div className={styles.dummyCards}>
              <AffiliateDummyList
                items={Array.from({
                  length: NUM_DUMMYCARDS,
                }, () => null)} />
            </div>
          }

          {affiliateListError &&
            <ErrorView />
          }
        </Section>
      </Wrapper>
      {affiliateCategoriesLoaded && !affiliateCategoryError &&
        <OffCanvasFilterMenu
          isOpen={openFiltermenu.isOpen}
          close={openFiltermenu.closeFiltermenu}
          filterState={filterState} />
      }
    </div>
  );
};

export default AffiliateSubpage;
